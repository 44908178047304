.description_container {
  position: relative;
}

.description_popover {
  position: absolute;
  z-index: 2;

  padding: 1rem;
  width: 400px;
  left: 50%;
  transform: translateX(-50%);

  background-color: #fff;
  box-shadow: 0px 2px 4px 0px #00000014;
  border: 1px solid #e8e8ea;

  border-radius: 8px;

  display: none;
  visibility: none;
}

.description_container:hover .description_popover {
  display: block;
  visibility: visible;
}
